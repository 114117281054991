<template>
  <a-spin :spinning="spinning">
    <!-- <page-header :title="l('印章管理')"></page-header> -->

    <div class="seal-manage">
      <div class="seal-list">
        <div class="item">
          <div class="img-wrapper">
            <img src="" />
          </div>
          <div class="status">
            申请中
          </div>
          <div class="title">测试</div>
          <div class="btn-wrapper">
            <a-button type="link">申请进度</a-button>
          </div>
        </div>

        <div class="upload">
          <div class="wrapper">
            <div class="icon-wrapper">
              <a-icon type="plus" />
            </div>
            <div class="title">添加印章</div>
          </div>
        </div>
      </div>

    </div>

  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";

export default {
  mixins: [AppComponentBase],
    name: "seal-manage",
    data() {
      return {
        spinning: false,
      }
    },
    created() {

    },
    mounted() {
  
    },
    methods: {
 
    },
    components: {

    }
}
</script>

<style lang="less" scope>
@import "../../../styles/common.less";

.seal-manage{
  padding: 0 20px;
  background-color: @color-white;
  .seal-list{
    #flex;
    #flex-hw;

    .item, .upload{
      position: relative;
      #tac;
      #mrt20;
      #mrr20;
      border: 1px solid @color-border-gray;
      transition: all .2s linear;
      width: 240px;
      height: 280px;
      overflow: hidden;
      z-index: 1;
      .img-wrapper{
        padding-top: 32px;
        padding-bottom: 16px;
        img{
          // #w100;
          width: 140px;
          height: 140px;
        }
      }
      .status{
        position: absolute;
        right: -28px;
        top: 12px;
        transform: rotate(45deg);
        height: 22px;
        text-align: center;
        line-height: 22px;
        // background-color: @color-gray;
        background-color: @color-blue;
        width: 100px;
        justify-content: center;
        font-size: 12px;
        color: @color-white;
      }
      .title{
        #fs16;
        #w100;
        color: @color-lightblack;
      }
      .btn-wrapper{
        position: absolute;
        bottom: 0;
        left: 0;
        #w100;
        line-height: 44px;
        border-top: 1px solid @color-border-gray;
      }
      &:active{
        transform: translateY(3px);
      }
      &:hover{
        transform: translateY(-3px);
      }
    }
    .upload{
      // #flex;
      // #flex-hc;
      // #flex-hw;
      &:before{
        content: "";
        display: block;
        position: absolute;
        left: 8px;
        right: 8px;
        top: 8px;
        bottom: 8px;
        border: 1px dashed @color-border-gray;
      }
      &:active:before{
        border: 1px dashed @color-border-gray;
      }
      &:hover:before{
        border: 1px dashed @color-blue;
      }
      .icon-wrapper{
        font-size: 40px;
      }
      .wrapper{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        color: @color-lightblack;

        svg{
          font-size: 50px;
        }
        .title{

        }
      }
    }
  }
}
</style>
